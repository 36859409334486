import { useState } from "react";

const GetQuickAccess = ({message}) => {
  const [close, setClose] = useState(false);

  if (close) return null;

  return (
    <div className="getQuickAccess">
      <div className="copy">
        <p className="title">Permissions missing</p>
        <p className="text">{message}</p>
      </div>
      <div className="button" >
        <button onClick={() => setClose(!close)} />
      </div>
    </div>
  )
}

export default GetQuickAccess;