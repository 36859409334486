
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
import React, { useEffect, useMemo, useRef } from 'react';

// interface ZxingOptions {
//   hints?: Map<DecodeHintType, any>;
//   constraints?: MediaStreamConstraints;
//   timeBetweenDecodingAttempts?: number;
//   onResult?: (result: Result) => void;
//   onError?: (error: Error) => void;
// }

const useZxing = ({
  constraints = {
    audio: false,
    video: { facingMode: 'environment' },
  },
  hints,
  timeBetweenDecodingAttempts = 300,
  onResult = () => {},
  onError = () => {},
}) => {
  const ref = useRef(null);
  const wrapperRef = useRef(null);

  const reader = useMemo(() => {
    const instance = new BrowserMultiFormatReader(hints);
    instance.timeBetweenDecodingAttempts = timeBetweenDecodingAttempts;
    return instance;
  }, [hints, timeBetweenDecodingAttempts]);

  useEffect(() => {
    if (!wrapperRef.current || !ref.current) return;

    reader.decodeFromConstraints(constraints, ref.current, (result, error) => {
      if (result && result.text) onResult(result.text);
      if (error) onError(error);
    });
    return () => {
      reader.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, reader, wrapperRef]);

  return { ref, wrapperRef };
};

export const Scanner = ({
  onResult,
  onError = () => {},
}) => {
  const { ref, wrapperRef } = useZxing({ onResult, onError, hints });

  return (
    <div ref={wrapperRef} className="scanner">
      <div className="scannerVideoContainer">
        <video ref={ref} />
      </div>
    </div>
  );
};

const hints = new Map().set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE]);;
