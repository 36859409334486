import { useState, useEffect } from "react";
import "./App.css";
import { Scanner } from "./qrCodeScanner.jsx";
import { useGeolocation } from "./useGeolocation.jsx";
import GetQuickAccess from "./GetQuickAccess";

const DEV_ENDPOINT = 'https://2yntkgy86e.execute-api.eu-central-1.amazonaws.com/prod/';
const DEV_APIKEY = 'gPDCAyqK72aYOlz0aupt48eLa96wYlvCK8TnDWEg';

const PROD_ENDPOINT = 'https://eczz8szad9.execute-api.eu-central-1.amazonaws.com/prod/';
const PROD_API_KEY = 'GqHRCTOyrPasyqb2BV1I972bgcciBcGFI2YwFK18';

const ENDPOINT = window?.location?.host === 'scanaris.app'
  ? PROD_ENDPOINT
  : DEV_ENDPOINT;

const API_KEY = window?.location?.host === 'scanaris.app'
  ? PROD_API_KEY
  : DEV_APIKEY;

const STATUS = {
  LOCATION: 'location',
  CAMERA: 'camera', 
  DONE: 'done',
  // TODO: Add when 1. processing camera permissions, 2. processing location permissions
  LOADING: 'loading',
  SCANNING: null,
}

const App = () => {
  const [scanaryStatus, setScanaryStatus] = useState(STATUS.CAMERA);
  const [scanResult, setScanResult] = useState(undefined);
  const [reportResult, setReportResult] = useState(undefined);
  const [quickNavMessage, setQuickNavMessage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [coords, available, enabled, locationError: error] = useGeolocation(scanaryStatus === STATUS.LOCATION);

  const sendData = () => {
    setScanaryStatus(STATUS.LOADING);

    fetch(`${ENDPOINT}report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
      body: JSON.stringify({
        qrCodeContent: scanResult,
        latitude: coords.latitude,
        longitude: coords.longitude,
        precision: coords.accuracy,
      }),
    })
      .then((response) => response.json())
      .then(setReportResult)
      .catch(console.error)
      .finally(() => {
          setScanaryStatus(STATUS.DONE);
    
          setTimeout(() => {
            setScanaryStatus(STATUS.SCANNING);
            setScanResult(null);
            setReportResult(null);
          }, 4000);
      });
  };

  useEffect(() => {
    // TODO: Have Scanary report loading state when permission is granted and camera is loading
    // TODO: Put this in a custom hook, just like useGeolocation
    if (navigator.mediaDevices) {
      const cameraPermissionsDeniedMsg = "Could not access your camera. Still want to scan? Update your phone's settings.";
      
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks()
            .forEach(track => track.stop());
          
          async function checkPermissions() {
            const permissions = await navigator.permissions.query({ name: "camera" });
            
            if (["granted", "prompt"].includes(permissions.state)) {
              setQuickNavMessage(null);
              setScanaryStatus(STATUS.SCANNING);
            } else {
              setQuickNavMessage(cameraPermissionsDeniedMsg);
            }
          }
      
          checkPermissions();
        })
        .catch(() => {
          setQuickNavMessage(cameraPermissionsDeniedMsg);
        });
    }
  }, []);

  useEffect(() => {
    // After scanning something, we ask for location permissions
    if (scanResult) {
      if (coords) {
        sendData();
      } else {
        setScanaryStatus(STATUS.LOCATION);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, scanResult]);

  useEffect(() => {
    if (scanResult && locationError) {
      setQuickNavMessage(locationError);
    } else {
      setQuickNavMessage(null);
    }
   }, [scanResult, locationError]);

  return (
    <div className="app">
      <Scanary status={scanaryStatus} reportResult={reportResult} />
      {scanaryStatus !== STATUS.CAMERA ? <Scanner onResult={setScanResult} /> : <div className="scanner-placeholder" />}
      <ManualInput sendData={setScanResult} />
      {quickNavMessage && <GetQuickAccess message={quickNavMessage} />}
    </div>
  );
};

const ManualInput = ({ sendData }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    } else {
      setValue(event.target.value);
    }
  }

  const handleSubmit = (event) => {
    sendData(value);

    event.preventDefault();
  }

  return (
    <form className="manualInput" onSubmit={handleSubmit}>
      <input type="text" value={value} onChange={handleChange} placeholder="Enter code"/>
      <p>e.g. <span>LDL</span>1234567 or <span>R1N</span>76543212345678</p>
    </form>
  );
}

const Scanary = ({ status, reportResult }) => {
  const [className, setClassName] = useState('');
  let instructions;

  useEffect(() => { 
    if (status) {
      setClassName('animate');

      setTimeout(() => { 
        setClassName('');
      }, 1000);
    }
  }, [status])

  switch (status) {
    case 'camera':
      instructions = "Please give me access to your camera, so I can scan codes.";
      break;
    case 'location':
      instructions = "Could I get access to your location, so I can report where the scan happened?";
      break;
    case 'done':
      instructions = reportResult?.message ?? "Nice one, thanks for scanning!";
      break;
    case 'loading':
      instructions = "Chirp chirp, processing ...";
      break;
    default:
      instructions = null;
  }

  return (
    <div className="scanary-wrapper">
     {instructions && <div className="scanary-text"><p>{instructions}</p></div>}
      <div className={`scanary ${className}`} style={{backgroundColor: reportResult?.color}}>🐣</div>
    </div>
  )
}

export default App;
