import { useCallback, useEffect, useState } from "react";

// TODO: Have Scanary report loading state when permission is granted and coordinates are loading
export const useGeolocation = (setPrompt) => {
  const [coords, setCoords] = useState(undefined);
  const [available, setAvailable] = useState(undefined);
  const [enabled, setEnabled] = useState(false);

  const [locationError, setError] = useState(undefined);

  // Check if geolocation is available
  useEffect(() => {
    if (!navigator.geolocation) {
      setAvailable(false);
      return;
    }

    async function checkPermissions() {
      const permissions = await navigator.permissions.query({ name: "geolocation" });
      setAvailable(["granted", "prompt"].includes(permissions.state));
    }

    checkPermissions();
  }, []);

  useEffect(() => {
    if (!enabled && setPrompt) {
      setEnabled(true);
      navigator.geolocation.getCurrentPosition(onSuccessFn, onErrorFn);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, setPrompt])

  function onSuccess(position) {
    setCoords(position.coords);
  }
  const onSuccessFn = useCallback(onSuccess, [setCoords]);

  function onError(error) {
    setError("To identify the vehicle's location, we need precise location permissions. On iOS, you can grant them in Settings -> Privacy & Security -> Location Services -> Your Browser. On Android, go to Settings -> Privacy -> Permission Manager -> Location -> Your Browser.");
  }
  const onErrorFn = useCallback(onError, [setError]);

  return [coords, available, enabled, locationError];
}
